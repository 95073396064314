<script>
  import { get } from 'vuex-pathify'

  export default {
    props: {
      achievement: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...get('achievements', ['getAchievement']),
      currentAchievement() {
        return this.getAchievement(this.achievement)
      },
    },
    methods: {
      goBack() {
        this.$navigate({ name: 'AccountAchievements' })
      },
    },
  }
</script>

<template>
  <BaseContainer max-width="lg">
    <div class="mt-5">
      <BaseIconClickable label="Back" color="black" @click="goBack">
        <IconArrowLeft />
      </BaseIconClickable>
    </div>

    <component :is="currentAchievement.icon" class="w-30 m-auto mt-5" />

    <BaseHeading size="h2" class="text-center my-6">
      Upgrade to <br />
      {{ currentAchievement.label }}
    </BaseHeading>

    <div v-if="currentAchievement.criteria" class="text-center my-6">
      {{ currentAchievement.criteria }}
    </div>

    <BaseSlider
      v-if="currentAchievement.images.length"
      has-navigation
      has-pagination
      class="md:m-auto"
    >
      <BaseSliderSlide
        v-for="(image, index) in currentAchievement.images"
        :key="index"
        class="slide"
      >
        <BaseImage :src="image" alt="" />
      </BaseSliderSlide>
    </BaseSlider>

    <template v-if="currentAchievement.description">
      <BaseDivider class="my-6" />

      <div class="my-6">
        {{ currentAchievement.description }}
      </div>
    </template>

    <BaseDivider class="my-6" />

    <div class="text-xs">
      Your {{ achievement }} upgrade will renew automatically along with your Membership using the
      payment method associated with your account. To prevent renewal, you can cancel in your
      account page, or by emailing us at <a href="mailto:help@alltrue.com">help@alltrue.com</a>. All
      prices listed are in USD.
    </div>
  </BaseContainer>
</template>
